import React from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';

export const Dialog = DialogPrimitive.Root;

export const DialogTrigger = ({ children, ...props }) => (
  <DialogPrimitive.Trigger asChild {...props}>
    {children}
  </DialogPrimitive.Trigger>
);

export const DialogContent = ({ children, descriptionId }) => (
  <DialogPrimitive.Portal>
    <DialogPrimitive.Overlay className="fixed inset-0 bg-black/50" />
    <DialogPrimitive.Content
      className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-lg shadow-lg"
      aria-describedby={descriptionId}
    >
      {children}
    </DialogPrimitive.Content>
  </DialogPrimitive.Portal>
);

export const DialogTitle = ({ children }) => (
  <DialogPrimitive.Title className="text-lg font-bold mb-4">
    {children}
  </DialogPrimitive.Title>
);

export const DialogDescription = ({ children, id }) => (
  <DialogPrimitive.Description id={id} className="text-sm text-gray-500">
    {children}
  </DialogPrimitive.Description>
);
