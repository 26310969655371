// src/pages/Usuarios/Roles.js

import React from 'react';

export const Roles = () => {
  return (
    <div>
      <h2 className="text-xl font-bold mb-4">Roles</h2>
      {/* Contenido para roles */}
    </div>
  );
};
