import { ApolloClient, InMemoryCache } from '@apollo/client';
import  createUploadLink  from 'apollo-upload-client/createUploadLink.mjs';
import { setContext } from '@apollo/client/link/context';
import store from '../store';

// Crear el enlace para manejar cargas de archivos
const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL || 'https://api.optica.center/graphql/',
});

// Agregar el token de autenticación al encabezado
const authLink = setContext((_, { headers }) => {
  const token = store.getState().auth.token;
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

// Configurar Apollo Client
const client = new ApolloClient({
  link: authLink.concat(uploadLink), // Usar el enlace de autenticación seguido del enlace de carga
  cache: new InMemoryCache(),
});

export default client;
