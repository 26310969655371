// src/graphql/mutations.js
import { gql } from '@apollo/client';


export const CREATE_EMPRESA_MUTATION = gql`
  mutation CreateEmpresa(
    $rut: String!
    $razonSocial: String!
    $direccion: String!
    $giro: String!
  ) {
    createEmpresa(
      rut: $rut
      razonSocial: $razonSocial
      direccion: $direccion
      giro: $giro
    ) {
      success
      message
    }
  }
`;

export const UPDATE_EMPRESA_MUTATION = gql`
  mutation UpdateEmpresa(
    $rut: String!
    $razonSocial: String
    $direccion: String
    $giro: String
    $profileImage: Upload
  ) {
    updateEmpresa(
      rut: $rut
      razonSocial: $razonSocial
      direccion: $direccion
      giro: $giro
      profileImage: $profileImage
    ) {
      success
      message
    }
  }
`;