import React, { useState, useEffect, useRef } from 'react';
import { LuPanelLeftClose, LuPanelLeftOpen } from 'react-icons/lu';
import { useAppShell } from '../app-shell/AppShell';
import { useSelector, useDispatch } from 'react-redux';
import { logout, setCredentials } from '../../store/slices/authSlice';
import { jwtDecode } from 'jwt-decode'; // Corrige la importación
import { gql, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { UPDATE_EMPRESA_MUTATION } from '../../graphql/mutations';
import { UserIcon } from './UserIcon';
import { UserDropdown } from './UserDropdown';
import { Dialog, DialogContent, DialogTitle, DialogDescription } from '../dialog/Dialog';
import { EditCompanyForm } from './EditCompanyForm';
import { LoadingSpinner } from '../../components/LoadingSpinner';

const SELECT_COMPANY_MUTATION = gql`
  mutation SelectCompany($token: String!, $companyRut: String!) {
    selectCompany(token: $token, companyRut: $companyRut) {
      jwtToken
      message
    }
  }
`;

export const TopBar = () => {
  const { toggleSidebar, isSidebarCollapsed } = useAppShell();
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [updateEmpresa] = useMutation(UPDATE_EMPRESA_MUTATION);
  const [selectCompany] = useMutation(SELECT_COMPANY_MUTATION);
  const dropdownRef = useRef(null);

  // Validación de token
  const isTokenValid = typeof auth.token === 'string' && auth.token.trim() !== '';
  let decodedToken = null;
  let empresasRuts = [];
  let currentCompanyRut = null;
  let userInitial = 'U';

  // Decodificar token solo si es válido
  if (isTokenValid) {
    try {
      decodedToken = jwtDecode(auth.token);
      empresasRuts = decodedToken.empresas_ruts || [];
      currentCompanyRut = decodedToken.company_rut || null;
      userInitial = auth.user?.nombre?.charAt(0).toUpperCase() || 'U';
    } catch (error) {
      console.error('Error decodificando el token:', error);
    }
  } else if (auth.token) {
    // Muestra un mensaje solo si el token existe pero no es válido
    console.warn('Token no válido o vacío.');
  }

  const handleLogout = () => {
    setLoading(true);
    setTimeout(() => {
      dispatch(logout());
      navigate('/login');
      setLoading(false);
    }, 1000);
  };

  const handleSwitchCompany = async (companyRut) => {
    setLoading(true);
    try {
      navigate('/dashboard', { replace: true });
      const response = await selectCompany({
        variables: {
          token: auth.token,
          companyRut: companyRut,
        },
      });
      const { jwtToken } = response.data.selectCompany;
      const newDecodedToken = jwtDecode(jwtToken);
      const roles = newDecodedToken.roles;

      dispatch(setCredentials({ token: jwtToken, roles, user: newDecodedToken }));
      setDropdownOpen(false);
    } catch (err) {
      console.error('Error al cambiar de empresa:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleEditCompany = async (data) => {
    try {
      setLoading(true);
      const response = await updateEmpresa({
        variables: {
          rut: currentCompanyRut,
          razonSocial: data.razonSocial || null,
          direccion: data.direccion || null,
          giro: data.giro || null,
          profileImage: data.profileImage || null,
        },
      });
      console.log('Empresa actualizada:', response.data);
      setIsDialogOpen(false);
    } catch (error) {
      console.error('Error actualizando la empresa:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenConfigDialog = () => {
    setDropdownOpen(false);
    setIsDialogOpen(true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="flex items-center justify-between px-4 py-2 bg-stone-100">
      <button
        onClick={toggleSidebar}
        className="text-gray-600 hover:text-gray-800 focus:outline-none"
      >
        {isSidebarCollapsed ? <LuPanelLeftOpen size={24} /> : <LuPanelLeftClose size={24} />}
      </button>

      <h1 className="text-xl font-bold"></h1>

      <div className="relative" ref={dropdownRef}>
        {isTokenValid && !isDialogOpen && (
          <>
            <UserIcon
              userInitial={userInitial}
              onClick={() => setDropdownOpen(!dropdownOpen)}
            />
            {dropdownOpen && (
              <UserDropdown
                userName={auth.user?.nombre || 'Usuario'}
                userEmail={auth.user?.email || 'correo@ejemplo.com'}
                empresasRuts={empresasRuts}
                currentCompanyRut={currentCompanyRut}
                onSwitchCompany={handleSwitchCompany}
                onLogout={handleLogout}
                onClose={() => setDropdownOpen(false)}
                onOpenConfigDialog={handleOpenConfigDialog}
              />
            )}
          </>
        )}
      </div>

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent>
          <DialogTitle>Editar Empresa</DialogTitle>
          <DialogDescription id="dialog-description">
            Aquí puedes actualizar los datos de tu empresa.
          </DialogDescription>
          <EditCompanyForm
            initialData={{ razonSocial: '', giro: '', direccion: '' }}
            onSubmit={handleEditCompany}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};
