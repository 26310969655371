import React from 'react';

export const SessionButton = React.forwardRef(({ children, className = '', ...rest }, ref) => {
  return (
    <button
      ref={ref}
      {...rest}
      className={`bg-session-blue text-white px-4 py-2 rounded-md tracking-wide font-medium shadow-md text-sm active:scale-95 transition-transform ${className}`}
    >
      {children}
    </button>
  );
});
