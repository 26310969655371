import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAppShell } from '../app-shell/AppShell';
import { useSelector } from 'react-redux';
import { LuLayoutGrid, LuUsers } from 'react-icons/lu';
import logo from '../../assets/logo.png'; // Versión rectangular con texto
import logoIn from '../../assets/logo_in.png'; // Versión cuadrada sin texto

export const Sidebar = () => {
  const { isSidebarCollapsed } = useAppShell();
  const roles = useSelector((state) => state.auth.roles);

  // Define el permiso para acceder a "Usuarios"
  const canAccessUsuarios = roles.includes('SuperUser'); // Solo "SuperUser" puede ver "Usuarios"

  return (
    <div
      className={`h-full bg-stone-200 shadow-md transition-all duration-300 ${
        isSidebarCollapsed ? 'w-16' : 'w-64'
      }`}
    >
      {/* Contenedor del logo */}
      <div className="flex items-center p-3 h-16">
        <div className="flex items-center h-full">
          {/* Cambia el logo dependiendo del estado de la barra lateral */}
          <img
            src={isSidebarCollapsed ? logoIn : logo}
            alt="Optica Center Logo"
            className="h-7 transition-all duration-200"
          />
        </div>
      </div>

      {/* Navegación */}
      <nav className="mt-4 space-y-2 px-2">
        {/* Enlace al dashboard principal */}
        <NavLink
          to=""
          className="flex gap-2 items-center p-2 hover:bg-white/40 rounded-md"
        >
          <LuLayoutGrid />
          {!isSidebarCollapsed && (
            <span className="text-sm font-medium">Dashboard</span>
          )}
        </NavLink>

        {/* Opción "Usuarios" */}
        {canAccessUsuarios && (
          <NavLink
            to="usuarios"
            className="flex gap-2 items-center p-2 hover:bg-white/40 rounded-md"
          >
            <LuUsers />
            {!isSidebarCollapsed && (
              <span className="text-sm font-medium">Usuarios</span>
            )}
          </NavLink>
        )}
      </nav>
    </div>
  );
};
