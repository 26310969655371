// src/pages/Usuarios/Administrar.js

import React from 'react';

export const Administrar = () => {
  return (
    <div>
      <h2 className="text-xl font-bold mb-4">Administrar Usuarios</h2>
      {/* Contenido para administrar usuarios */}
    </div>
  );
};
