 // src/store/index.js
 import { configureStore } from '@reduxjs/toolkit';
 import authReducer from './slices/authSlice';
 
 const store = configureStore({
   reducer: {
     auth: authReducer,
     // Agrega más slices según sea necesario
   },
 });
 export default store;
 