// src/pages/Usuarios/Usuarios.js

import React from 'react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const Usuarios = () => {
  const roles = useSelector((state) => state.auth.roles);
  const location = useLocation();

  // Define qué pestañas están disponibles para el usuario según sus roles
  const tabs = [
    { name: 'Administrar', path: 'administrar', roles: ['SuperUser', 'Admin'] },
    { name: 'Roles', path: 'roles', roles: ['SuperUser'] },
    { name: 'Permisos', path: 'permisos', roles: ['SuperUser'] },
  ];

  // Filtra las pestañas según los roles del usuario
  const availableTabs = tabs.filter((tab) =>
    tab.roles.some((role) => roles.includes(role))
  );

  return (
    <div className="p-4">
      {/* Navegación de pestañas */}
      <div className="flex border-b border-gray-200 mb-4">
        {availableTabs.map((tab) => (
          <NavLink
            key={tab.path}
            to={tab.path}
            className={({ isActive }) =>
              `px-4 py-2 -mb-px font-medium text-sm ${
                isActive ? 'border-b-2 border-blue-500 text-blue-600' : 'text-gray-600 hover:text-blue-600'
              }`
            }
            end
          >
            {tab.name}
          </NavLink>
        ))}
      </div>

      {/* Contenido de la pestaña seleccionada */}
      <div>
        <Outlet />
      </div>
    </div>
  );
};
