// src/App.js
import { SelectCompany } from './pages/SelectCompany/SelectCompany';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Home } from './pages/Home/Home';
import { Login } from './pages/Login/Login';
import { DashboardLayout } from './pages/Dashboard/DashboardLayout';
import { Content as DashboardContent } from './pages/Dashboard/Content';
import ProtectedRoute from './components/ProtectedRoute';
import { Usuarios } from './pages/Usuarios/Usuarios';
import { Administrar } from './pages/Usuarios/Administrar';
import { Roles as RolesPage } from './pages/Usuarios/Roles';
import { Permisos } from './pages/Usuarios/Permisos';

function App() {
  return (
    <Router>
      <Routes>
        {/* Rutas públicas */}
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
         <Route
          path="/select-company"
          element={
            <ProtectedRoute
              component={SelectCompany}
              requiresCompany={false} // Company context is not required for this route
            />
          }
        />



        {/* Ruta protegida para el dashboard con rutas anidadas */}
        <Route
          path="/dashboard/*"
          element={<ProtectedRoute component={DashboardLayout} />}
        >
          {/* Ruta principal del dashboard */}
          <Route index element={<DashboardContent />} />

          {/* Rutas protegidas para "Usuarios" */}
          <Route
            path="usuarios/*"
            element={
              <ProtectedRoute
                component={Usuarios}
                roles={['SuperUser']} // Solo "SuperUser" puede acceder a "Usuarios"
              />
            }
          >
            {/* Rutas anidadas dentro de "Usuarios" */}
            <Route path="administrar" element={<Administrar />} />
            <Route
              path="roles"
              element={
                <ProtectedRoute
                  component={RolesPage}
                  roles={['SuperUser']} // Solo "SuperUser" puede acceder a "Roles"
                />
              }
            />
            <Route
              path="permisos"
              element={
                <ProtectedRoute
                  component={Permisos}
                  roles={['SuperUser']} // Solo "SuperUser" puede acceder a "Permisos"
                />
              }
            />
            {/* Redireccionar a "administrar" si no se especifica una subruta */}
            <Route index element={<Administrar />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
