// src/pages/Usuarios/Permisos.js
import React from 'react';

export const Permisos = () => {
  return (
    <div>
      <h2 className="text-xl font-bold mb-4">Permisos</h2>
      {/* Contenido para permisos */}
    </div>
  );
};
