// src/components/layout/Layout.js

import React from 'react';
import { AppShell, AppShellContent, AppShellSidebar } from '../app-shell/AppShell';
import { Sidebar } from '../sidebar/Sidebar';
import { TopBar } from '../topbar/TopBar';

export const Layout = ({ children }) => {
  return (
    <AppShell>
      <AppShellSidebar width="240px">
        <Sidebar />
      </AppShellSidebar>
      <AppShellContent>
        <div className="flex flex-col h-full">
          <TopBar />
          <div className="flex-grow overflow-auto p-4 bg-stone-100">{children}</div>
        </div>
      </AppShellContent>
    </AppShell>
  );
};
