// src/components/select-company/SelectCompany.js

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import { setCredentials } from '../../store/slices/authSlice';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { Fade } from '../../components/Fade/Fade';
import { UserIcon } from '../../components/topbar/UserIcon';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../../core/firebase';

const SELECT_COMPANY_MUTATION = gql`
  mutation SelectCompany($companyRut: String!) {
    selectCompany(companyRut: $companyRut) {
      jwtToken
      message
    }
  }
`;

export const SelectCompany = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const [selectCompany] = useMutation(SELECT_COMPANY_MUTATION);
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [imagesLoading, setImagesLoading] = React.useState(true);
  const [profileImageUrls, setProfileImageUrls] = React.useState([]);

  useEffect(() => {
    if (!auth.token) {
      setLoading(true);
      navigate('/login');
    } else {
      setLoading(false);
    }
  }, [auth.token, navigate]);

  useEffect(() => {
    // Generar URLs firmadas para las imágenes
    const fetchImageUrls = async () => {
      const decodedToken = jwtDecode(auth.token);
      const empresasProfileImages = decodedToken.empresas_profile_images || [];
      const empresasRuts = decodedToken.empresas_ruts || [];

      try {
        const urls = await Promise.all(
          empresasProfileImages.map(async (value, index) => {
            if (value === '') {
              // La imagen existe, generar la ruta relativa y obtener URL firmada
              const path = `empresas/${empresasRuts[index]}/profile_image`;
              try {
                const storageRef = ref(storage, path);
                return await getDownloadURL(storageRef);
              } catch (err) {
                // Si la imagen no existe o hay un error, devuelve null
                return null;
              }
            } else {
              // Es una inicial, devolver el valor tal cual
              return value;
            }
          })
        );
        setProfileImageUrls(urls);
      } catch {
        setProfileImageUrls([]); // En caso de error, usamos una lista vacía
      } finally {
        setImagesLoading(false);
      }
    };

    fetchImageUrls();
  }, [auth.token]);

  const decodedToken = jwtDecode(auth.token);
  const empresasRuts = decodedToken.empresas_ruts || [];
  const empresasRazonSocial = decodedToken.empresas_razon_social || [];

  const handleSelectCompany = async (companyRut) => {
    setLoading(true);
    try {
      const response = await selectCompany({
        variables: {
          companyRut: companyRut,
        },
        context: {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        },
      });

      const { jwtToken } = response.data.selectCompany;
      const newDecodedToken = jwtDecode(jwtToken);
      const roles = newDecodedToken.roles;

      dispatch(setCredentials({ token: jwtToken, roles, user: newDecodedToken }));

      navigate('/dashboard');
    } catch (err) {
      console.error(err);
      setError('Error al seleccionar la empresa.');
      setLoading(false);
    }
  };

  if (loading || imagesLoading) {
    return <LoadingSpinner />;
  }

  if (empresasRuts.length === 0) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <p>No tienes empresas asociadas.</p>
      </div>
    );
  }

  return (
    <Fade className="min-h-screen flex items-center justify-center bg-gradient-to-r from-purple-200 via-pink-200 to-yellow-100">
      <div className="w-full max-w-md bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-center mb-6">Seleccione la Empresa</h2>
        {error && <div className="text-red-500 mb-4">{error}</div>}
        <ul>
          {empresasRuts.map((companyRut, index) => {
            const razonSocial = empresasRazonSocial[index] || companyRut;
            const profileImage = profileImageUrls[index];

            return (
              <li key={companyRut} className="mb-2 flex items-center space-x-4">
                <UserIcon
                  imageUrl={profileImage && profileImage.startsWith('http') ? profileImage : null}
                  userInitial={
                    profileImage && !profileImage.startsWith('http')
                      ? profileImage // Iniciales proporcionadas
                      : razonSocial.charAt(0).toUpperCase()
                  }
                />
                <button
                  onClick={() => handleSelectCompany(companyRut)}
                  className="flex-grow p-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                >
                  {razonSocial}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </Fade>
  );
};
