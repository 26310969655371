import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SessionButton } from '../../components/buttons/SessionButton';
import logo from '../../assets/logo.png';
import homeImage from '../../assets/home.png';

export const Home = () => {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/login');
  };

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-r from-purple-200 via-pink-200 to-yellow-100 overflow-hidden">
      {/* Header */}
      <header className="flex justify-between items-center px-4 sm:px-6 py-4 bg-white/50-transparent backdrop-blur-sm w-full z-10">
        <img src={logo} alt="Optica Center Logo" className="h-10" />
        <div>
          <SessionButton onClick={handleLoginClick}>Iniciar Sesión</SessionButton>
        </div>
      </header>

      {/* Main Content */}
      <main className="flex-grow flex flex-col-reverse lg:flex-row items-center justify-center px-4 lg:px-8 gap-10">
        {/* Text Section */}
        <div className="text-center lg:text-left flex flex-col justify-center items-center lg:items-start max-w-lg lg:mb-0">
          <h2 className="text-lg font-semibold bg-gradient-to-r from-blue-600 via-purple-500 to-pink-500 bg-clip-text text-transparent mb-2">
            Disfruta administrando tu Óptica
          </h2>
          <h1 className="text-3xl md:text-4xl font-extrabold text-gray-900 leading-tight mb-4">
            Hacemos tu experiencia más fácil con{' '}
            <span className="text-blue-600">Optica Center</span>.
          </h1>
          <p className="text-gray-600 text-base md:text-lg mb-6">
            Explora las soluciones más avanzadas en óptica para profesionales y clientes. Gestiona tus servicios ópticos desde una plataforma integral.
          </p>
        </div>

        {/* Image Section */}
        <div className="flex-shrink-0 w-full lg:w-1/2 flex items-center justify-center">
          <img
            src={homeImage}
            alt="Optica Center Home"
            className="w-full h-auto max-w-xs sm:max-w-md lg:max-w-lg object-contain"
          />
        </div>
      </main>

      {/* Footer */}
      <footer className="p-4 bg-footer-transparent text-center text-gray-800">
        © {new Date().getFullYear()} Optica Center
      </footer>
    </div>
  );
};
