import React from 'react';
import { Dialog, DialogTrigger, DialogContent } from '../dialog/Dialog';

export const UserDropdown = ({
  userName = "Usuario",
  userEmail = "correo@ejemplo.com",
  empresasRuts,
  currentCompanyRut,
  onSwitchCompany,
  onLogout,
  onClose,
  onOpenConfigDialog, // Nueva prop para abrir el diálogo de configuración
}) => {
  return (
    <div className="absolute right-0 mt-2 w-64 bg-white bg-opacity-90 backdrop-blur-md border border-gray-300 rounded-lg shadow-lg z-50">
      {/* Usuario */}
      <div className="p-4 border-b border-gray-300">
        <div className="flex items-center space-x-3">
          <div className="w-10 h-10 rounded-full bg-gray-300 flex items-center justify-center">
            <span className="text-gray-700 font-medium">
              {userName?.charAt(0).toUpperCase()}
            </span>
          </div>
          <div>
            <p className="font-medium text-gray-900">{userName}</p>
            <p className="text-sm text-gray-500">{userEmail}</p>
          </div>
        </div>
      </div>

      {/* Empresas */}
      {empresasRuts?.length > 1 && (
        <div className="border-b border-gray-300">
          <div className="flex items-center justify-between px-4 py-2 text-gray-600 text-sm font-medium">
            <span>Empresas</span>
            {/* Ícono de configuración */}
            <button
              onClick={onOpenConfigDialog}
              className="text-blue-500 hover:text-blue-700"
            >
              ⚙️
            </button>
          </div>
          <ul>
            {empresasRuts
              .filter((rut) => rut !== currentCompanyRut)
              .map((companyRut) => (
                <li
                  key={companyRut}
                  className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-200 cursor-pointer"
                  onClick={() => {
                    onSwitchCompany(companyRut);
                    onClose();
                  }}
                >
                  {companyRut}
                </li>
              ))}
          </ul>
        </div>
      )}

      {/* Opciones adicionales */}
      <div className="py-2">
        <button
          className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-200"
          onClick={() => {
            onLogout();
            onClose();
          }}
        >
          Cerrar sesión
        </button>
      </div>
    </div>
  );
};
