// src/pages/Dashboard/Content.js

import React from 'react';
import { useAppShell } from '../../components/app-shell/AppShell';

export const Content = () => {
  const { toggleSidebar, isSidebarCollapsed } = useAppShell();
  return (
    <div className="p-4">
      <div className="flex gap-8 items-center">
        <h1 className="text-xl font-bold">Dashboard</h1>
      </div>
      <div className="grid grid-cols-3 gap-4 py-4">
        {/* Tarjetas o contenido del dashboard */}
        <div className="aspect-square bg-gradient-to-br from-white to-white/10 rounded-xl"></div>
        <div className="aspect-square bg-gradient-to-br from-white to-white/10 rounded-xl"></div>
        <div className="aspect-square bg-gradient-to-br from-white to-white/10 rounded-xl"></div>
        <div className="aspect-video bg-gradient-to-br from-white to-white/10 rounded-xl"></div>
        <div className="aspect-video bg-gradient-to-br from-white to-white/10 rounded-xl"></div>
      </div>
    </div>
  );
};
