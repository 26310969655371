import React, { useState, useEffect } from 'react';
import { auth } from '../../core/firebase';
import { useDispatch } from 'react-redux';
import { setCredentials } from '../../store/slices/authSlice';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { gql, useMutation } from '@apollo/client';
import { SessionButton } from '../../components/buttons/SessionButton';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { Fade } from '../../components/Fade/Fade';
import logo from '../../assets/logo.png';
import loginImage from '../../assets/home.png';

const AUTHENTICATE_USER_MUTATION = gql`
  mutation AuthenticateUser($token: String!) {
    authenticateUser(token: $token) {
      jwtToken
      message
    }
  }
`;

export const Login = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [authenticateUser] = useMutation(AUTHENTICATE_USER_MUTATION);

  const formatRut = (rut) => {
    // Your existing formatRut function
  };

  const formik = useFormik({
    initialValues: {
      rut: '',
      password: '',
    },
    validationSchema: Yup.object({
      rut: Yup.string().required('Requerido'),
      password: Yup.string().required('Requerido'),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const email = `${values.rut}@optica.center`;

        const userCredential = await auth.signInWithEmailAndPassword(email, values.password);
        const idToken = await userCredential.user.getIdToken();

        const response = await authenticateUser({
          variables: {
            token: idToken,
          },
        });

        const { jwtToken } = response.data.authenticateUser;
        const decodedToken = jwtDecode(jwtToken);
        const roles = decodedToken.roles;
        const empresasRuts = decodedToken.empresas_ruts;

        dispatch(setCredentials({ token: jwtToken, roles, user: decodedToken }));

        if (empresasRuts && empresasRuts.length > 0) {
          navigate('/select-company');
        } else {
          setError('No hay empresas asociadas a tu cuenta.');
          setLoading(false);
        }
      } catch (err) {
        console.error(err);
        setError('Error de autenticación. Verifique sus credenciales.');
        setLoading(false);
      }
    },
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="min-h-screen flex flex-col md:flex-row bg-gradient-to-r from-purple-200 via-pink-200 to-yellow-100 items-center md:items-stretch">
      <Fade className="hidden md:flex md:flex-1 items-center justify-center">
        <img src={loginImage} alt="Login Illustration" className="max-w-md lg:max-w-lg w-full h-auto" />
      </Fade>
      <Fade className="flex-1 flex items-center justify-center p-8">
        <div className="w-full max-w-md bg-white p-6 rounded-lg shadow-lg">
          <a href="/" className="flex justify-center mb-6">
            <img src={logo} alt="Optica Center Logo" className="h-12" />
          </a>
          <h2 className="text-2xl font-bold text-center mb-6">Bienvenido de nuevo</h2>
          {error && <div className="text-red-500 mb-4">{error}</div>}
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-4">
              <input
                type="text"
                name="rut"
                placeholder="RUT"
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                onChange={(e) => {
                  const rawRut = e.target.value.replace(/\D/g, '');
                  formik.setFieldValue('rut', rawRut);
                }}
                value={formatRut(formik.values.rut)}
              />
              {formik.errors.rut && <div className="text-red-500 text-sm">{formik.errors.rut}</div>}
            </div>
            <div className="mb-4">
              <input
                type="password"
                name="password"
                placeholder="Contraseña"
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                onChange={formik.handleChange}
                value={formik.values.password}
              />
              {formik.errors.password && <div className="text-red-500 text-sm">{formik.errors.password}</div>}
            </div>
            <SessionButton type="submit" className="w-full">
              Iniciar Sesión
            </SessionButton>
          </form>
        </div>
      </Fade>
    </div>
  );
};
