// src/pages/Dashboard/DashboardLayout.js

import React from 'react';
import { Layout } from '../../components/layout/Layout';
import { Outlet } from 'react-router-dom';

export const DashboardLayout = () => {
  return (
    <Layout>
      <Outlet /> {/* Renderiza el contenido de las rutas anidadas */}
    </Layout>
  );
};
