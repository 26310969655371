//src\store\slices\authSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: localStorage.getItem('jwtToken') || null,
  roles: JSON.parse(localStorage.getItem('roles') || '[]'),
  user: JSON.parse(localStorage.getItem('user') || 'null'),
  loading: false, // Estado de carga inicial
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials(state, action) {
      const { token, roles, user } = action.payload;
      state.token = token;
      state.roles = roles;
      state.user = user;
      state.loading = false;
      localStorage.setItem('jwtToken', token);
      localStorage.setItem('roles', JSON.stringify(roles));
      localStorage.setItem('user', JSON.stringify(user));
    },
    logout(state) {
      state.token = null;
      state.roles = [];
      state.user = null;
      state.loading = false;
      localStorage.removeItem('jwtToken');
      localStorage.removeItem('roles');
      localStorage.removeItem('user');
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const { setCredentials, logout, setLoading } = authSlice.actions;
export default authSlice.reducer;
