import React, { useEffect, useState, useRef } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../store/slices/authSlice';
import { jwtDecode } from 'jwt-decode';
import { LoadingSpinner } from './LoadingSpinner';

// Función para verificar si un token ha expirado
function isTokenExpired(token) {
  if (!token) return true; // Si no hay token, se considera expirado
  try {
    const { exp } = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Tiempo actual en segundos
    return exp < currentTime; // Verifica si el token ha expirado
  } catch {
    return true; // Si ocurre un error al decodificar, consideramos el token expirado
  }
}

function ProtectedRoute({ component: Component, roles, requiresCompany = true, ...rest }) {
  const token = useSelector((state) => state.auth.token);
  const userRoles = useSelector((state) => state.auth.roles);
  const dispatch = useDispatch();

  const [redirectPath, setRedirectPath] = useState(null);
  const [loading, setLoading] = useState(true);
  const hasLoggedInvalidTokenRef = useRef(false); // Ref para controlar logs

  useEffect(() => {
    const validateAndRedirect = () => {
      if (!token) {
        if (!hasLoggedInvalidTokenRef.current && process.env.NODE_ENV === 'development') {
          console.info('Usuario no autenticado. Redirigiendo a login.');
          hasLoggedInvalidTokenRef.current = true;
        }
        dispatch(logout());
        setRedirectPath('/login');
        setLoading(false);
        return;
      }

      if (isTokenExpired(token)) {
        if (!hasLoggedInvalidTokenRef.current && process.env.NODE_ENV === 'development') {
          console.warn('Token expirado. Redirigiendo a login.');
          hasLoggedInvalidTokenRef.current = true;
        }
        dispatch(logout());
        setRedirectPath('/login');
        setLoading(false);
        return;
      }

      try {
        const { company_rut } = jwtDecode(token);
        const hasCompanySelected = !!company_rut;

        if (requiresCompany && !hasCompanySelected) {
          setRedirectPath('/select-company');
        } else if (!requiresCompany && hasCompanySelected) {
          setRedirectPath('/dashboard');
        } else {
          setRedirectPath(null); // No se requiere redirección
        }
      } catch (error) {
        console.error('Error decodificando token en ProtectedRoute:', error);
        dispatch(logout());
        setRedirectPath('/login');
      } finally {
        setLoading(false);
      }
    };

    validateAndRedirect();
  }, [token, dispatch, requiresCompany]);

  // Mostrar cargando mientras se valida el token
  if (loading) {
    return <LoadingSpinner />;
  }

  // Redirigir si es necesario
  if (redirectPath) {
    return <Navigate to={redirectPath} />;
  }

  // Verificar si el usuario tiene los roles requeridos
  if (roles && !roles.some((role) => userRoles.includes(role))) {
    console.warn('Usuario no autorizado para esta ruta.');
    return <Navigate to="/unauthorized" />;
  }

  // Renderizar el componente protegido
  return <Component {...rest} />;
}

export default ProtectedRoute;
