import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

export const EditCompanyForm = ({ initialData, onSubmit }) => {
  const formik = useFormik({
    initialValues: {
      razonSocial: initialData.razonSocial || '',
      giro: initialData.giro || '',
      direccion: initialData.direccion || '',
      profileImage: null,
    },
    validationSchema: Yup.object({
      razonSocial: Yup.string().nullable(), // Opcional
      giro: Yup.string().nullable(), // Opcional
      direccion: Yup.string().nullable(), // Opcional
    }),
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const handleFileChange = (e) => {
    formik.setFieldValue('profileImage', e.target.files[0]); // Archivo se asigna correctamente
  };

  return (
    <form onSubmit={formik.handleSubmit} className="space-y-4">
      <div>
        <label className="block text-gray-700">Razón Social</label>
        <input
          type="text"
          name="razonSocial"
          value={formik.values.razonSocial}
          onChange={formik.handleChange}
          className="w-full p-2 border rounded"
        />
        {formik.errors.razonSocial && <p className="text-red-500 text-sm">{formik.errors.razonSocial}</p>}
      </div>
      <div>
        <label className="block text-gray-700">Giro</label>
        <input
          type="text"
          name="giro"
          value={formik.values.giro}
          onChange={formik.handleChange}
          className="w-full p-2 border rounded"
        />
        {formik.errors.giro && <p className="text-red-500 text-sm">{formik.errors.giro}</p>}
      </div>
      <div>
        <label className="block text-gray-700">Dirección</label>
        <input
          type="text"
          name="direccion"
          value={formik.values.direccion}
          onChange={formik.handleChange}
          className="w-full p-2 border rounded"
        />
        {formik.errors.direccion && <p className="text-red-500 text-sm">{formik.errors.direccion}</p>}
      </div>
      <div>
        <label className="block text-gray-700">Imagen de Perfil</label>
        <input
          type="file"
          name="profileImage"
          onChange={handleFileChange}
          className="w-full"
        />
      </div>
      <button type="submit" className="w-full bg-blue-500 text-white py-2 rounded">
        Guardar Cambios
      </button>
    </form>
  );
};
