// src/components/app-shell/AppShell.js

import React, { createContext, useContext, useState } from 'react';
import './AppShell.css';

const AppShellContext = createContext();

export const useAppShell = () => {
  return useContext(AppShellContext);
};

export const AppShell = ({ children }) => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  return (
    <AppShellContext.Provider value={{ isSidebarCollapsed, toggleSidebar }}>
      <div className="app-shell">{children}</div>
    </AppShellContext.Provider>
  );
};

export const AppShellSidebar = ({ width, children }) => {
  const { isSidebarCollapsed } = useAppShell();

  return (
    <aside
      className={`app-shell__sidebar ${isSidebarCollapsed ? 'collapsed' : ''}`}
      style={{ width: isSidebarCollapsed ? '64px' : width }}
    >
      {children}
    </aside>
  );
};

export const AppShellContent = ({ children }) => {
  return <main className="app-shell__content">{children}</main>;
};
