// src/index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import { Provider } from 'react-redux';
import store from './store';
import client from './core/apolloClient';
import { ApolloProvider } from '@apollo/client';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
    <App />
    </ApolloProvider>
  </Provider>
);