import React, { useEffect, useState } from 'react';

export const Fade = ({ children, className = '', duration = 700 }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 50); // Small delay to trigger the fade-in effect

    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className={`transition-opacity duration-${duration} ${
        isVisible ? 'opacity-100' : 'opacity-0'
      } ${className}`}
    >
      {children}
    </div>
  );
};
