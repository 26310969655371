// src/components/topbar/UserIcon.js

import React from 'react';

export const UserIcon = ({ imageUrl, userInitial }) => {
  return (
    <div className="w-10 h-10 rounded-full bg-gray-300 flex items-center justify-center overflow-hidden">
      {imageUrl ? (
        <img src={imageUrl} alt="User" className="w-full h-full object-cover" />
      ) : (
        <span className="text-gray-700 font-medium">{userInitial}</span>
      )}
    </div>
  );
};
